<template>
  <v-card>
    <v-card-title class="text-h6">{{ $t("labels.return_check") }}</v-card-title>
    <v-card-text>{{ $t("labels.return_check_message") }}</v-card-text>

    <v-card-text>
      <v-text-field
        v-model="code"
        class="c-input-xs"
        :label="$t('labels.tracking_OD_DC')"
        :placeholder="$t('labels.tracking_OD_DC')"
        autofocus
        dense
        single-line
        outlined
        clearable
        hide-details
        @keyup.enter="confirm"
      ></v-text-field>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="cancel">
        {{ $t("labels.close") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "CheckOrderReturn",
  data: () => ({
    code: null,
    isLoading: false,
  }),
  mounted() {},
  methods: {
    async confirm() {
      if (!this.code) {
        return false;
      }

      if (this.isLoading) {
        this.code = null;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post(
          "/dashboard/v1/check-order-return-has-item-out-of-stock",
          { code: this.code }
        );
        this.isLoading = false;
        this.code = null;
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.isLoading = false;
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.warning(errMsg);
        document.getElementById("error_sound_player").play();
      }
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style scoped></style>
